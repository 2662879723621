<template>
  <div class="list-table">
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-subheader>ผู้ใช้งาน</v-subheader>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-toolbar flat color="white">
              <v-text-field
                id
                name
                flat
                prepend-icon="search"
                placeholder="ชื่อ-นามสกุล/ชื่อผู้ใช้/อีเมล์"
                v-model="search"
                hide-details
                class="hidden-sm-and-down"
              ></v-text-field>
              <v-btn icon>
                <v-icon>filter_list</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <!-- Data table -->
              <v-data-table
                :loading="loading"
                :headers="complex.headers"
                :search="search"
                :items="complex.items"
                :items-per-page-options="[
                  10,
                  25,
                  50,
                  { text: 'All', value: -1 }
                ]"
                class="elevation-1"
                item-key="name"
                v-model="complex.selected"
                @click:row="handleClick"
              >
                <template v-slot:item.avatar="{ item }">
                  <v-avatar>
                    <img :src="item.avatar" alt="avatar" size="16" />
                  </v-avatar>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-menu>
                    <template v-slot:activator="{ on: menu }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                          <v-btn icon v-on="{ ...menu }">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>
                        <span>Action</span>
                      </v-tooltip>
                    </template>
                    <v-list class="pa-0" dense>
                      <v-list-item
                        v-for="action in actions"
                        :key="action.text"
                        @click="action.click(item)"
                      >
                        <v-list-item-icon class="mr-2">
                          <v-icon small>{{ action.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ action.text }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!-- Dialog edit -->
    <v-dialog v-model="dialog" max-width="150vh">
      <v-card>
        <v-toolbar card>Edit User</v-toolbar>
        <v-card-text>
          <form>
            <v-text-field
              v-model="formModel.name"
              :counter="10"
              label="Name"
              required
            ></v-text-field>
            <v-text-field
              v-model="formModel.email"
              label="E-mail"
              required
            ></v-text-field>
            <v-divider class="mt-3 mb-3"></v-divider>
            <v-btn @click="handleSubmit">submit</v-btn>
          </form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialog Detail -->
    <v-dialog v-model="dialogDetail" max-width="150vh" persistent scrollable>
      <v-card>
        <v-toolbar card color="primary"
          ><v-btn icon dark @click="dialogDetail = false">
            <v-icon>mdi-close</v-icon> </v-btn
          ><v-toolbar-title>ข้อมูลผู้ใช้งาน</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col>
              <h3>รหัส</h3>
              <p>{{ formModel.code }}</p>
              <h3>ชื่อ-นามสกุล</h3>
              <p>{{ formModel.fullname }}</p>
              <h3>อีเมล์</h3>
              <p>{{ formModel.email }}</p>
              <h3>ชื่อผู้ใช้</h3>
              <p>{{ formModel.user_name }}</p>
              <!-- <h3>กิจกรรม</h3>
              <ul id="example-1">
                <li v-for="item in formModel.activities" :key="item.id">
                  <v-switch
                    v-model="disabled"
                    class="ma-2"
                    label="item"
                  ></v-switch>
                </li>
              </ul> -->
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialogDetail = false">
            ปิด
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import User from '@/api/user'
import { mapGetters } from 'vuex'
import Util from '@/util'
import { getUser } from '@/api/users'

export default {
  data() {
    return {
      loading: false,
      formModel: {
        id: '',
        name: '',
        username: '',
        email: ''
      },
      dialog: false,
      dialogDetail: false,
      search: '',
      complex: {
        selected: [],
        headers: [
          {
            text: '#',
            value: 'id'
          },
          {
            text: 'รหัส',
            value: 'code'
          },
          {
            text: 'ชื่อ-นามกุล',
            value: 'fullname'
          },
          {
            text: 'ชื่อผู้ใช้',
            value: 'username'
          },
          {
            text: 'อีเมล์',
            value: 'email'
          },
          {
            text: 'จัดการ',
            value: 'action'
          }
        ],
        items: []
      },
      actions: [
        {
          text: 'รายละเอียด',
          icon: 'mdi-eye',
          click: this.handleViewItem
        },
        {
          text: 'แก้ไข',
          icon: 'mdi-pencil',
          click: this.handleEditItem
        },
        {
          text: 'ลบ',
          icon: 'mdi-close',
          click: this.handleDeleteItem
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'getLoginUser'])
  },
  methods: {
    fetchData() {
      this.loading = true
      getUser('', 100, 0)
        .then((users) => {
          this.complex.items = users
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleClick(row) {
      this.formModel = Object.assign(this.formModel, row)
      this.dialogDetail = true
    },
    handleSubmit() {
      this.loading = true
      // User.login()
    },
    handleViewItem() {},
    handleEditItem(row) {
      // console.log(row)
      const loginUser = this.getLoginUser
      Util.goToLuSystem(
        loginUser.lu_token,
        `/admin/personnels/${row.lu_id}/edit`
      )
    },
    handleDeleteItem() {}
  }
}
</script>
