const Items = [
  // {
  //   uuid: '65a6eb21-67b5-45c3-9af7-faca2d9b60d5',
  //   firs_name: 'Banpot',
  //   last_name: 'Srihawong',
  //   email: 'banpot.sr@gmail.com',
  //   username: 'banpot.sr@gmail.com',
  //   password: '111111',
  //   phone: '02000000',
  //   avatar: '/static/avatar/a2.jpg',
  //   role: ['super_admin']
  // },
  // {
  //   uuid: '65a6eb21-67b5-45c3-9af7-faca2d9b60d4',
  //   firs_name: 'Dessie',
  //   last_name: 'Dessie',
  //   email: 'admin@gmail.com',
  //   username: 'admin@gmail.com',
  //   password: '111111',
  //   phone: '02000000',
  //   avatar: '/static/avatar/a2.jpg',
  //   role: ['admin']
  // },
  // {
  //   uuid: '28d9f265-74d7-4f85-83d4-6a21fca57dcf',
  //   first_name: 'Jakayla',
  //   last_name: 'Jakayla',
  //   email: 'Jakayla_Crooks867@yahoo.com',
  //   username: 'Jakayla_Crooks86',
  //   password: '111111',
  //   phone: '610.499.1240',
  //   avatar: '/static/avatar/a3.jpg',
  //   role: ['lecturer']
  // },
  // {
  //   uuid: '14ddae1e-986d-42f4-8d17-46a02d469b2b',
  //   first_name: 'Hobart',
  //   last_name: 'Hobart',
  //   email: 'Hobart_Mueller.Thiel@hotmail.com',
  //   username: 'Hobart_Mueller',
  //   password: '111111',
  //   phone: '1-590-385-3349',
  //   avatar: 'static/avatar/a1.jpg',
  //   role: ['super_admin']
  // }
]
import { fetchGet, fetchPost } from './fetch'
const url = '/api/v1/user'
const authUrl = '/api/v1/login'

// const userAuth = Items.map((value) => {
//   value.password = '111111'
//   return value
// })

const getUserById = (userId) => {
  const apiUrl = `${url}/${userId}`
  return fetchGet(apiUrl, { id: userId })
    .then((user) => {
      return Promise.resolve(user)
    })
    .catch((error) => {
      console.error(error)
      return Promise.reject(error)
    })
  // return uuid === undefined ? Items[0] : Items.find((x) => x.uuid === uuid)
}

const getUser = (q, limit, offset) => {
  return fetchGet(url, { q, limit, offset })
    .then((users) => {
      users.map((u) => {
        u.fullname = `${u.first_name} ${u.last_name}`
        return u
      })
      return Promise.resolve(users)
    })
    .catch((error) => {
      console.error(error)
      return Promise.reject(error)
    })
  // return limit ? Items.slice(0, limit) : Items
}

const login = (username, password) => {
  if (username === undefined || password === undefined) {
    return false
  }
  return fetchPost(authUrl, { username, password })
    .then((user) => {
      return Promise.resolve(user)
    })
    .catch((error) => {
      console.error(error)
      return Promise.reject(error)
    })

  // const user = userAuth.find((x) => {
  //   return x.username == username && x.password == password
  // })
  // if (!user) {
  //   return false
  // }
  // console.log(user)
  // return user
}

export { Items, getUser, getUserById, login }
